import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const PrivacyPolicyPage = ({data, path}) => (
  <Layout title="Privacy Policy" includeBanner={true} lightHeader={true} contentClassName="content-body"
    seoComponent={<SEO title="Privacy Policy" path={path} description="At GummySearch, accessible from gummysearch.com, one of our main priorities is the privacy of our visitors." />}
  >
    <h2 className="c7" id="h.kr5vdfevic1y">
    <span className="c1">
        Privacy Statement for gummysearch.com</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from gummysearch.com or any of it's subdomains (the &quot;Site&quot;).</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.wqfvwytjvymn">
            <span className="c1">
        Personal information we collect</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as &quot;Device Information&quot;.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        We collect Device Information using the following technologies:</span>
        </p>
        <ul className="c6 lst-kix_dh84hpcx3vnf-0 start">
            <li className="c4">
            <span className="c0">
        &quot;Cookies&quot; are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</span>
        </li>
        <li className="c4">
            <span className="c0">
        &quot;Log files&quot;, and User Accounts, track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</span>
        </li>
        <li className="c4">
            <span className="c0">
        &quot;Web beacons&quot;, &quot;tags&quot;, and &quot;pixels&quot; are electronic files used to record information about how you browse the Site.</span>
        </li>
        </ul>
        <p className="mb-4 c8">
            <span className="c0">
        Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number. We refer to this information as &quot;Order Information&quot;.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.yupuvqgagik7">
            <span className="c1">
        Personal User Information</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        When we talk about &quot;Personal Information&quot; in this Privacy Policy, we are talking both about Device Information and Order Information.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        How do we use your personal information?</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        We use the Order Information that we collect generally to fulfill any advertisement orders placed through the Site (including processing your payment information and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:</span>
        </p>
        <ul className="c6 lst-kix_3rm1cw2itikw-0 start">
            <li className="c4">
            <span className="c0">
        Communicate with you;</span>
        </li>
        <li className="c4">
            <span className="c0">
        Screen our orders for potential risk or fraud; and</span>
        </li>
        <li className="c4">
            <span className="c0">
        When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</span>
        </li>
        </ul>
        <p className="mb-4 c8">
            <span className="c0">
        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        We use your account information that we collect generally to generate statistics for you, and provide personalized roadmaps. Additionally, we use this information to:</span>
        </p>
        <ul className="c6 lst-kix_x69jfl3mj9ib-0 start">
            <li className="c4">
            <span className="c0">
        Communicate with you;</span>
        </li>
        <li className="c4">
            <span className="c0">
        When in line with the preferences you have shared with us, provide you with information or advertising relating to our products, resources, or services.</span>
        </li>
        </ul>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.isnf7rw4h9p8">
            <span className="c1">
        Sharing you personal Information</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        We share your Personal Information with third parties to understand how our users use the site, in order to improve it.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        For example, we use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        We fund our site by showing adverts as you browse our site. In some cases we sell adverts directly to interested parties. However, these adverts are sometimes managed by a partner specializing in providing adverts for multiple sites. Invariably these partners collect anonymous data about the websites you visits so they can personalize the adverts to you, ensure that you don&#39;t see the same adverts too frequently and ultimately report to advertisers on which adverts are working.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        {/*<h2 className="c7" id="h.p16p3ns3bs38">
            <span className="c1">
        Behavioural advertising</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c11">
        As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative&#39;s (&quot;NAI&quot;) educational page at </span>
        <span className="c9">
            <a className="c3" href="https://www.google.com/url?q=http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work&amp;sa=D&amp;ust=1607530158923000&amp;usg=AOvVaw0Sft7hvNjhJJ79PEsssvD9">
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>
        </span>
        <span className="c0">
        .</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        You can opt out of targeted advertising by using the links below:</span>
        </p>
        <ul className="c6 lst-kix_ojiltu7g2g7u-0 start">
            <li className="c4">
            <span className="c0">
        - Facebook: https://www.facebook.com/settings/?tab=ads</span>
        </li>
        <li className="c4">
            <span className="c0">
        - Google: https://www.google.com/settings/ads/anonymous</span>
        </li>
        <li className="c4">
            <span className="c0">
        - Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</span>
        </li>
        </ul>
        <p className="mb-4 c8">
            <span className="c11">
        Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance&#39;s opt-out portal at: </span>
        <span className="c9">
            <a className="c3" href="https://www.google.com/url?q=http://optout.aboutads.info/&amp;sa=D&amp;ust=1607530158924000&amp;usg=AOvVaw3DT4ZxZ0i7pzItLkRl8ZIw">
        http://optout.aboutads.info/</a>
        </span>
        <span className="c0">
        .</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        Note: Opting out will not disable ads, they will just no longer be tailored to you.</span>
        </p>
        <p className="mb-4 c8 c10">
            <span className="c0">
        </span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>*/}
        <h2 className="c7" id="h.4gwjddmqu121">
            <span className="c1">
        Your rights</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</span>
        </p>
        <p className="mb-4 c8">
            <span className="c0">
        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.9pskg22qhspb">
            <span className="c1">
        Data retention</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        When you take some actions on this site, we will maintain some information about your actions for our records unless and until you ask us to delete this information.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.65yxfgw0iz5">
            <span className="c1">
        Changes</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.7fbw5vrw8qg2">
            <span className="c1">
        Links</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        gummysearch.com contains links to other sites. We are not responsible for the privacy practices or the content of such Web sites. This privacy document applies only to gummysearch.com and it's subdomains.</span>
        </p>
        <hr/>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>
        <h2 className="c7" id="h.d0q5t2rja2tv">
            <span className="c1">
        Contact us</span>
        </h2>
        <p className="mb-4 c8">
            <span className="c0">
        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e&#8209;mail at fed@gummysearch.com</span>
        </p>
        <p className="mb-4 c5">
            <span className="c2">
        </span>
        </p>


  </Layout>
)


export default PrivacyPolicyPage
